<template>
  <div>
    <div class="flex justify-between items-center">
      <a-button @click="$router.back()" class="flex mb-4">
        Regresar
      </a-button>
      <template v-if="!loading">
        <a outlined target="_blank" v-if="currentSemester === 6 && passed === true && message === 'student_has_passed_all_courses'" class="flex justify-end mb-2 border-2 border-blue-700 rounded-md p-2 text-blue-600"
          :href="`${url}/api/students/${$route.params.id}/reports/courses-final-notes-global`">
            Imprimir histórico de notas
        </a>
      </template>
    </div>
    <template v-if="!loading">
      <div v-for="(value, attribute, key) in history" :key="key" class="bg-white rounded-xl overflow-hidden" :class="{ 'mt-2': key > 0 }">
        <div class="flex justify-between flex-wrap items-center space-x-2 mb-2 px-2 pt-2">
          <h4 class="text-2xl">{{ attribute }}</h4>
          <div class="flex space-x-2 flex-wrap">
            <a outlined target="_blank" class="border-2 border-blue-700 rounded-md p-2 text-blue-600" :href="`${url}/api/students/${$route.params.id}/reports/courses-notes?period_id=${value[0].period_id}&cut=1&token=${token}`">
              Imprimir notas corte 1
            </a>
            <a outlined target="_blank" class="border-2 border-blue-700 rounded-md p-2 text-blue-600" :href="`${url}/api/students/${$route.params.id}/reports/courses-notes?period_id=${value[0].period_id}&cut=2&token=${token}`">
              Imprimir notas corte 2
            </a>
            <a outlined target="_blank" :href="`${url}/api/students/${$route.params.id}/reports/courses-final-notes?period_id=${value[0].period_id}&token=${token}`" class="border-2 border-blue-700 rounded-md p-2 text-blue-600">
              Boletín final
            </a>
          </div>
        </div>
        <a-table :columns="columns" :source="history[attribute]">
<!--          <template #td(cumulative)="{ item }">-->
<!--            &lt;!&ndash;            {{ item.pivot.final_delivery_weight_note + item.pivot.cut_weight_note }}&ndash;&gt;-->
<!--            {{ (item.pivot.cumulative_weighting_prc * 20) / 100 }}-->
<!--          </template>-->
          <template #td(final_delivery)="{ item }">
            {{ item.final_delivery ? item.pivot.final_delivery_note : 'N/A' }}
          </template>
<!--          <template #td(total)="{ item }">-->
<!--&lt;!&ndash;            {{ item.pivot.final_delivery_weight_note + item.pivot.cut_weight_note }}&ndash;&gt;-->
<!--            {{ item.pivot.final_note }}-->
<!--          </template>-->
          <template #td(final_note)="{ item }">
            {{ item.pivot.overwritten_final_note ? item.pivot.overwritten_final_note : item.pivot.final_note }}

<!--            <a-badge v-if="item.pivot.overwritten_final_note !== null">Modificado</a-badge>-->
          </template>
        </a-table>
      </div>
    </template>
    <template v-else>
      <a-skeleton class="h-10 w-full rounded-2xl" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { URL } from '@/constants';
const token = localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME)
export default {
  data: () => ({
    url: URL,
    token,
    history: {},
    columns: [
      { title: 'Materia', key: 'course.title' },
      // { title: 'Credito anterior', key: '' },
      { title: 'Créditos', key: 'credits' },
      { title: 'I Corte', key: 'pivot.first_cut_note' },
      { title: 'II Corte', key: 'pivot.second_cut_note' },
      // { title: 'Promedio', key: 'pivot.cut_average_note' },
      // { title: 'Acumulativo', slot: 'cumulative' },
      { title: 'Entrega final', slot: 'final_delivery' },
      // { title: 'Total', key: 'pivot.final_note' },
      { title: 'Final', slot: 'final_note' },
    ],
    loading: false,
    passed: false,
    currentSemester: 0,
    message: ''
  }),
  computed: mapState({
    user: ({ session }) => session.user
  }),
  methods: {
    getHistoryParts(attribute) {
      const year = attribute.slice(0, 3)
      const number = attribute.slice(3, 4)
      const semester = attribute.slice(4, 5)

      return {
        year,
        number,
        semester,
        period: `${year}-${number}`
      }
    }
  },
  mounted() {

    const { id } = this.$route.params

    // this.$repository.students
    //     .show(id, {
    //         with: 'latestInscription.period,latestInscription.specialization,latestInscription.semester,latestInscription.section'
    //     })
    //     .then(({ data }) => {
    //         this.student = data
    //     })

    this.loading = !this.loading
    this.$repository.students.specializationHistory(id)
      .then(({ data }) => {
        this.history = data
      });
      this.$repository.students
        .verifySemesterApproval(id)
        .then((response) => {
            this.passed = response.data.passed;
            this.currentSemester = response.data.current_semester.number
            this.message = response.data.message
        }).finally(() => this.loading = !this.loading)
    
  }
}
</script>
